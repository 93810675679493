import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/Footer.css';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>Company</h4>
          <Link to="/about" onClick={scrollToTop}>About Us</Link>
          <Link to="/contact" onClick={scrollToTop}>Contact</Link>
          <Link to="/legal" onClick={scrollToTop}>Legal</Link>

        </div>
        
        <div className="footer-section">
          <h4>Contact Us</h4>
          <p>Email: admin@joinchefprep.com</p>
          <p>Phone: (561) 525-6045</p>
          <p>254 Chapman Rd. STE 209, Newark, DE 19702</p>
        </div>
        
        <div className="footer-section">
          <h4>Follow Us</h4>
          <div className="social-links">
            <a href="https://www.facebook.com/joinchefprep" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://www.instagram.com/joinchefprep" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Chef Prep. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer; 
