import React from 'react';
import '../css/About.css';
import aboutUs from '../images/about-us.png';

function About() {
  return (
    <div className="about-page">
      <div className="about-content">
        <div className="about-left">
          <h1>Our company focuses <br />on <span className="highlight">food and people.</span></h1>
          
          <div className="who-we-are">
            <h2>Who we are</h2>
            <p>Chef Prep is a team of health minded individuals that saw a problem with the way people interact with food. We decided it was time to take a stand and make a change!</p>
          </div>

          <div className="our-mission">
            <h2>Our mission</h2>
            <p>By giving our users access to fresh, local, food on demand, we hope to make a healthier and happier world. None of us should have to choose between affordability, health, taste, or time. With an ever evolving world, we rely on tech for more and more - it's time to use this power in order to create something we can all be proud of!</p>
          </div>
        </div>

        <div className="about-right">
          <img src={aboutUs} alt="About Chef Prep" className="about-image" />
        </div>
      </div>
    </div>
  );
}

export default About; 