import React from 'react';
import homeImage from '../images/home-image.png';
import exploreOrder from '../images/explore-order.png';
import deliver from '../images/deliver.png';
import savor from '../images/savor.png';
import foodImage from '../images/Food-Image.png';
import convenienceIcon from '../images/convenience-icon.png';
import healthIcon from '../images/health-icon.png';
import affordabilityIcon from '../images/affordability-icon.png';
import joinNow from '../images/join-now.png';

function Home() {
  return (
    <div>
      <section className="hero">
      <div className="hero-content">
                  <h1>Your Local Flavor Marketplace</h1>
                  <p>Chef Prep is the all in one platform for finding and enjoying the best local meal prep services</p>
                </div>
                <div className="hero-image">
                  <img src={homeImage} alt="Meal prep containers with healthy food" />
                </div>
      </section>
      <section className="how-it-works">
      <h2>How it works.</h2>
                <div className="steps-container">
                  <div className="step">
                    <img src={exploreOrder} alt="Explore and Order" className="step-image" />
                    <h3>Explore & Order</h3>
                    <p>Browse your local meal prep services, find the menus or plates that best fit your taste, and place your order instantly!</p>
                  </div>

                  <div className="step">
                    <img src={deliver} alt="Delivery" className="step-image" />
                    <h3>Deliver</h3>
                    <p>Relax while your dedicated driver brings weekly meals to your door. No hassle needed - know when and where your food will be!</p>
                  </div>

                  <div className="step">
                    <img src={savor} alt="Savor" className="step-image" />
                    <h3>Savor</h3>
                    <p>Feel good knowing you're putting the best fuel into your body, at a fraction of the price of traditional meal services!</p>
                  </div>
                </div>
      </section>
      <section className="fresh-meals">
                <div className="fresh-meals-content">
                  <h2>A home for fresh meals</h2>
                  <p>Get peace of mind in knowing you have a weeks' worth of fresh meals in your fridge. Your food is made with care by local vendors, never mass produced in corporate kitchens.</p>
                </div>
                <div className="fresh-meals-image">
                  <img src={foodImage} alt="Person enjoying fresh meal" />
                </div>
              </section>
              <section className="why-chef-prep">
                <h2>Why Chef Prep?</h2>
                <div className="benefits-container">
                  <div className="benefit">
                    <img src={convenienceIcon} alt="Convenience" className="benefit-icon" />
                    <h3>Convenience</h3>
                    <p>Life can and does get busy for us all - save time and stress by having local artisans make your meals!</p>
                  </div>

                  <div className="benefit">
                    <img src={healthIcon} alt="Health Minded" className="benefit-icon" />
                    <h3>Health Minded</h3>
                    <p>Nothing should ever come between you and a healthy diet - Chef Prep pledges to always keep nutrition at the center of what we do.</p>
                  </div>

                  <div className="benefit">
                    <img src={affordabilityIcon} alt="Affordability" className="benefit-icon" />
                    <h3>Affordability</h3>
                    <p>Avoid outrageous up-charges that traditional meal delivery services hit you with. It's your money - don't throw it out!</p>
                  </div>
                </div>
              </section>
              <section className="join-now">
                <div className="join-now-left">
                  <img src={joinNow} alt="Chef Prep App" className="app-preview" />
                </div>
                <div className="join-now-right">
                  <h2>Download the app.</h2>
                  <button className="download-btn">Download</button>
                </div>
              </section>
            </div>
          );
        }

export default Home; 