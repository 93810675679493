import React, { useState } from 'react';
import '../css/FAQ.css';

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "How can I contact Chef Prep?",
      answer: "You can contact us by sending an email to admin@joinchefprep.com, and we will respond within 24 hours. You can also reach us through our contact form on the Contact page."
    },
    {
      question: "Is Chef Prep available in my state?",
      answer: "Currently, Chef Prep is available in select states including Texas and Florida. We're continuously expanding our service areas. If your state is not listed, follow us on social media for updates!"
    },
    {
      question: "Is there any cost to use Chef Prep?",
      answer: "Chef Prep is a free app that can be used by anyone. If you are an Android user, you can download the app from the Google Play Store. If you are an iOS user, you can download the app from the Apple App Store."
    },
    {
      question: "What is Chef Prep?",
      answer: "Chef Prep is a marketplace designed to connect consumers with meal prep services. In an age where everything is easily accessible, Chef Prep is crafted to provide you with numerous options for both your health and taste."
    },
    {
      question: "Is there a maximum order?",
      answer: "No, there is no maximum order size. However, please be aware that these plates are fresh and not mass-produced with preservatives."
    },
    {
      question: "I offer meal prep services, How can I contact Chef Prep?",
      answer: "We're always looking to partner with talented meal prep services! In the top right corner, click the 'Vendors' tab and fill out the form."
    }
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq-header">
        <h1>Frequently Asked</h1>
        <h1 className="questions">Questions</h1>
      </div>

      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <button 
              className={`faq-question ${activeIndex === index ? 'active' : ''}`}
              onClick={() => toggleAnswer(index)}
            >
              {faq.question}
              <span className="plus-icon">
                {activeIndex === index ? '−' : '+'}
              </span>
            </button>
            {activeIndex === index && (
              <div className="faq-answer">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ; 