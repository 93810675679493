import React from 'react';
import '../../css/Legal.css';

function Legal() {
  return (
    <div className="legal-container">
      <div className="legal-header">
        <h1>Legal</h1>
        <h1 className="information">Information</h1>
      </div>

      <div className="legal-content">
        <section className="terms-section">
          <h2>Terms of Service</h2>
          <div className="legal-text">
            <h3>1. Contractual Relationship; Termination; and Modification</h3>
            <p> In addition to these Terms of Service, your access to, and use of the Services is also governed
 by the applicable terms found on our website. These include but are not limited to: the Privacy
 Notice, which describes how we collect, use, and disclose your personal information; the User
 Generated Content Terms; Community Guidelines; Referral Policies; the Mobile Security
 Monitoring Terms and CHEF PREP’s other applicable CHEF PREP standards and policies
 (including, without limitation, CHEF PREP’s safety standards, the accessibility policies, and the
 U.S. Service Animal Policy), which we refer to collectively as the “Supplemental Terms.”
 Collectively, we refer to these Terms of Service and the Supplemental Terms as the “Terms.”
 These Terms govern your access or use, from within the United States and its territories and
 possessions, of the Services made available in the United States and its territories and
 possessions (the “Territory”). If you use the Services in another country, you agree to be subject
 to CHEF PREP’s terms of service for that country. In these Terms, the words “including” and
 "include” mean “including, but not limited to.”
 By accessing or using the Services, you confirm your agreement to be bound by these Terms. If
 you do not agree to these Terms, do not access or use the Services.
 Termination.
 CHEF PREP, in its sole discretion, may immediately terminate these Terms or any Services with
 respect to you, or generally cease offering or deny access to the Services or any portion thereof,
 at any time for any reason.
 Modification.
 CHEF PREPreserves the right to modify these Terms or its policies relating to the Services at
 any time, effective upon posting of an updated version of these Terms through the Services or
 CHEF PREP’s website. You should regularly review these Terms, as your continued use of the
 Services after any such changes constitutes your agreement to such changes</p>

            <h3>2. Arbitration Agreement</h3>
            <p>By agreeing to these Terms, you agree that you are required to resolve any claim that you may
 have against CHEF PREP on an individual basis in binding arbitration as set forth in this
 Arbitration Agreement, and not as a class, collective, coordinated, consolidated, mass and/or
 representative action. Binding arbitration is a procedure in which a dispute is submitted to one
 or more arbitrators who make a binding decision on the dispute. In choosing binding arbitration,
 you and CHEF PREP are opting for a private dispute resolution procedure where you agree to
 accept the arbitrator’s decision as final instead of going to court. You and CHEF PREP are each
 waiving your right to a jury trial.
 This Arbitration Agreement will preclude you from bringing any class, collective, coordinated,
 consolidated, mass, and/or representative action against CHEF PREP, and also preclude you
 from participating in or recovering relief in any current or future class, collective, coordinated,
 consolidated, mass and/or representative action brought against CHEF PREP by someone
 else—except as provided below in Section 2(a)(3)(c). Thus, the parties agree that the Arbitrator
 shall not conduct any form of class, collective, coordinated, consolidated, mass, and/or
representative arbitration, nor join, coordinate, or consolidate claims of multiple individuals
 against CHEF PREP in a single proceeding—except as provided below in Section 2(a)(3)(c).
 For the avoidance of doubt, except as provided below in Section 2(a)(3)(c), this Arbitration
 Agreement precludes you from bringing or participating in any kind of class, collective,
 coordinated, consolidated, mass, and/or representative or other kind of group, multi-plaintiff or
 joint action against CHEF PREP, other than participating in a classwide, collective, coordinated,
 consolidated, mass, and/or representative settlement of claims.
 (a) Agreement to Binding Arbitration Between You and CHEF PREP.
 (1) Covered Disputes: Except as expressly provided below in Section 2(b), you and CHEF
 PREP agree that any dispute, claim, or controversy in any way arising out of or relating to (i)
 these Terms and prior versions of these Terms, or the existence, breach, termination,
 enforcement, interpretation, scope, waiver, or validity thereof; (ii) your access to or use of the
 Services at any time; (iii) incidents or accidents resulting in personal injury or death to you or
 anyone else that you allege occurred in connection with your use of the Services (including, but
 not limited to, your use of the CHEF PREP Marketplace Platform or the driver version of the
 CHEF PREPApp), regardless of whether the dispute, claim, or controversy occurred or accrued
 before or after the date you agreed to these Terms, and regardless of whether you allege that
 the personal injury or death was experienced by you or anyone else; and (iv) your relationship
 with CHEF PREP, will be settled by binding individual arbitration between you and CHEF PREP,
 and not in a court of law. This Arbitration Agreement survives after your relationship with CHEF
 PREP ends.
 (2) Class Action Waiver: Any and all disputes, claims, or controversies between the parties shall
 be resolved only in individual arbitration. The parties expressly waive the right to have any
 dispute, claim, or controversy brought, heard, administered, resolved, or arbitrated as a class,
 collective, coordinated, consolidated, and/or representative action, and neither an arbitrator nor
 an arbitration provider shall have any authority to hear, arbitrate, or administer any class,
 collective, coordinated, consolidated, and/or representative action, or to award relief to anyone
 but the individual in arbitration. The parties also expressly waive the right to seek, recover, or
 obtain any non-individual relief. Notwithstanding anything else in this agreement, this Class
 Action Waiver does not prevent you or CHEF PREP from participating in a classwide, collective,
 and/or representative settlement of claims.
 The parties further agree that if for any reason a claim does not proceed in arbitration, this Class
 Action Waiver shall remain in effect, and a court may not preside over any action joining,
 coordinating, or consolidating the claims of multiple individuals against CHEF PREP in a single
 proceeding, except that this Class Action Waiver shall not prevent you or CHEF PREP from
 participating in a classwide, collective, and/or representative settlement of claims. If there is a
 final judicial determination that any portion of this Class Action Waiver is unenforceable or
 unlawful for any reason, (i) any class, collective, coordinated, consolidated, and/or
 representative claims subject to the unenforceable or unlawful portion(s) shall proceed in a court
 of competent jurisdiction; (ii) the portion of the Class Action Waiver that is enforceable shall be
 enforced in arbitration; (iii) the unenforceable or unlawful portion(s) shall be severed from this
 Arbitration Agreement; and (iv) severance of the unenforceable or unlawful portion(s) shall have
 no impact whatsoever on the enforceability, applicability, or validity of the Arbitration Agreement
 or the arbitrability of any remaining claims asserted by you or CHEF PREP.
(3) Mass Actions:
 a. Mass Action Waiver: Any and all disputes, claims, or controversies between the parties shall
 be resolved only in individual arbitration. The parties expressly waive the right to have any
 dispute, claim, or controversy brought, heard, administered, resolved, or arbitrated as a mass
 action, and neither an arbitrator nor an arbitration provider shall have any authority to hear,
 arbitrate, or administer any mass action or to award relief to anyone but the individual in
 arbitration—except as provided below in Section 2(a)(3)(c). The parties also expressly waive the
 right to seek, recover, or obtain any non-individual relief. The parties agree that the definition of
 a “Mass Action” includes, but is not limited to, instances in which you or CHEF PREP are
 represented by a law firm or collection of law firms that has filed 50 or more arbitration demands
 of a substantially similar nature against the other party within 180 days of the arbitration demand
 filed on your or CHEF PREP’s behalf, and the law firm or collection of law firms seeks to
 simultaneously or collectively administer and/or arbitrate all the arbitration demands in the
 aggregate. Notwithstanding anything else in this agreement, this Mass Action Waiver does not
 prevent you or CHEF PREP from participating in a mass settlement of claims.
 b. Dispute Procedure: Notwithstanding any provision to the contrary in the applicable arbitration
 provider’s rules, the arbitrator shall be empowered to determine whether the party bringing any
 claim has filed a Mass Action in violation of the Mass Action Waiver. Either party shall raise with
 the arbitrator or arbitration provider such a dispute within 15 days of its arising. If such a dispute
 arises before an arbitrator has been appointed, the parties agree that (i) a panel of three
 arbitrators shall be appointed to resolve only disputes concerning whether the party bringing any
 claim has filed a Mass Action in violation of the Mass Action Waiver. Each party shall select one
 arbitrator from the arbitration provider’s roster to serve as a neutral arbitrator, and these
 arbitrators shall appoint a third neutral arbitrator. If the parties’ arbitrators cannot agree on a
 third arbitrator, the arbitration provider will select the third arbitrator; (ii) CHEF PREP shall not
 pay any administrative fees or costs incidental to the appointment of Arbitrators under this
 provision, as well as any fees or costs that would not be incurred in a court proceeding, such as
 payment of the fees of the arbitrators, as well as room rental; (iii) the arbitrators shall issue a
 written decision with findings of fact and conclusions of law; and (iv) any further arbitration
 proceedings or assessment of arbitration-related fees shall be stayed pending the arbitrators’
 resolution of the parties’ dispute. If the arbitrator or panel of arbitrators determines that you have
 violated the Mass Action Waiver, the parties shall have the opportunity to opt out of arbitration
 within 30 days of the arbitrator’s or panel of arbitrator’s decision. You may opt out of arbitration
 by providing written notice of your intention to opt out to the arbitration provider and to CHEF
 PREP , LLC., Attn: Legal Department legal@joinchefprep.com, via email. This notice must be
 signed by you, and not any attorney, agent, or other representative of yours. CHEF PREP may
 opt out of arbitration by sending written notice of its intention to opt out to the arbitration provider
 and to you or your attorney, agent, or representative if you are represented. For the avoidance
 of doubt, the ability to opt out of arbitration described in this Section 2(a)(3)(b) only applies if the
 arbitrator or panel of arbitrators determines that you have violated the Mass Action Waiver. If the
 parties proceed with arbitration, the parties agree that arbitrations will be batched as provided in
 Section 2(a)(3)(c) below.
 c. Batching:
i. To increase efficiency of resolution in the event a Mass Action is filed and neither party
 exercises its right to opt out of arbitration pursuant to Section 2(a)(3)(b) above, the following
 procedure shall apply. At the request of either party, an arbitrator shall be selected according to
 the applicable arbitration provider’s rules to act as a special master (“Special Master”) to resolve
 threshold disputes regarding the propriety of some or all the arbitration demands submitted in
 the Mass Action (“Mass Arbitration Demands”). These threshold disputes may include, but are
 not limited to:
 1. Any dispute regarding filing fees owed with respect to the Mass Arbitration Demands,
 including whether claimants have submitted valid fee waivers;
 2. Any dispute regarding whether the applicable arbitration provider has complied with the
 Arbitration Agreement with respect to processing and administering the Mass Arbitration
 Demands;
 3. Any dispute regarding whether the Mass Arbitration Demands meet the requirements set
 forth in Section 2(d) below;
 4. Whether claimants are barred from proceeding with their claims based on a prior settlement
 agreement, violation of these Terms, or expiration of the statute of limitations;
 5. Any dispute relating to representation of the same claimant by multiple law firms;
 6. Any dispute regarding whether the Mass Arbitration Demands were filed with the correct
 arbitration provider;
 7. Any dispute regarding discovery common to all claims; and
 8. Any disputes regarding legal or factual issues common to all claims.
 Any such request shall be made within 15 days following the expiration of the opt-out period
 described in Section 2(a)(3)(b), and may be made by providing written notice to the arbitration
 provider. Upon the request of either party to appoint a Special Master to resolve the foregoing
 issues, the applicable arbitration provider shall refrain from further processing any of the Mass
 Arbitration Demands to which a dispute has been raised. No further payment for filing fees,
 administrative costs, or arbitrator fees shall be deemed due with respect to any of the Mass
 Arbitration Demands as to which a dispute has been raised until after the dispute(s) has/have
 been resolved by the Special Master. CHEF PREP shall not be responsible for the applicable
 arbitration provider’s and Special Master’s fees and costs related to the proceedings before the
 Special Master.
 A Special Master appointed pursuant to this procedure shall have no authority to consolidate
 cases.
 ii. After proceedings before the Special Master have concluded, to the extent any of the Mass
 Arbitration Demands are permitted to proceed, the parties shall group the Mass Arbitration
 Demands into batches of no more than 100 demands per batch by state of residence, and then
 alphabetically by last name (plus, to the extent there are less than 100 arbitration demands left
 over after the batching described above, a final batch consisting of the remaining demands),
 and shall inform the arbitration provider of the batches and their compositions within 14 days of
 the conclusion of proceedings before the Special Master. The arbitration provider shall treat
 each batch of claims as one case, with each case having one demand for arbitration, one
 appointed arbitrator, and one set of administrative documents and administrative and filing fees
 per batch. The parties shall randomly assign sequential numbers to each batch, and only one
 batch shall proceed to arbitration at a time in the order of the random sequential numbers. A
separate arbitrator will be appointed to, and administrative and filing fees assessed for, each
 batch as the batch proceeds to arbitration. You agree to cooperate in good faith with CHEF
 PREP and the arbitration provider to implement such a batch approach to resolution and fees.
 Nothing in this provision shall be construed as limiting the right to object that the filing or
 presentation of multiple arbitration demands by or with the assistance of the same law firm or
 organization violates any term of this Agreement.
 iii. If any Mass Arbitration Demands were originally processed as individual arbitration demands
 before this batching procedure was commenced, further proceedings, including the assessment
 of further arbitration filing or administration fees to either party shall be governed by the
 procedures set forth in this Section 2(a)(3).
 (4) Delegation Clause: Only an arbitrator, and not any federal, state, or local court or agency,
 shall have exclusive authority to resolve any dispute arising out of or relating to the
 interpretation, applicability, enforceability, or formation of this Arbitration Agreement, including
 without limitation any claim that all or any part of this Arbitration Agreement is void or voidable.
 An arbitrator shall also have exclusive authority to resolve all threshold arbitrability issues,
 including issues relating to whether these Terms are applicable, unconscionable, or illusory and
 any defense to arbitration, including without limitation waiver, delay, laches, or estoppel.
 However, only a court of competent jurisdiction, and not an arbitrator, shall have the exclusive
 authority to resolve any and all disputes arising out of or relating to the Class Action Waiver and
 Mass Action Waiver, including, but not limited to, any claim that all or part of the Class Action
 Waiver and/or Mass Action Waiver is unenforceable, unconscionable, illegal, void, or
 voidable—except that, as stated and pursuant to the procedures provided in Section 2(a)(3)(b),
 an arbitrator or panel of arbitrators shall have authority to determine whether the party bringing
 any claim has violated the Mass Action Waiver.
 (5) Application to Third Parties: This Arbitration Agreement shall be binding upon, and shall
 include any claims brought by or against any third parties, including but not limited to your
 spouse, domestic partner, heirs, estate, third-party beneficiaries and assigns, where their
 underlying claims arise out of or relate to your use of the Services. To the extent that any
 third-party beneficiary to this agreement brings claims against the Parties, those claims shall
 also be subject to this Arbitration Agreement.
 (b) Exceptions to Arbitration.
 Notwithstanding the foregoing, this Arbitration Agreement shall not require arbitration of the
 following claims: (i) individual claims brought in small claims court so long as the matter remains
 in such court and advances only on an individual basis; (ii) individual claims of sexual assault or
 sexual harassment occurring in connection with your use of the Services; and/or (iii) injunctive
 or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened
 infringement, misappropriation, or violation of a party’s copyrights, trademarks, trade secrets,
 patents, or other intellectual property rights.
 Such claims may be brought and litigated in a court of competent jurisdiction by you on an
 individual basis only. On an individual basis means that you cannot bring such claims as a class,
 collective, coordinated, consolidated, mass, and/or representative action against CHEF PREP.
 For the avoidance of doubt, this precludes you from bringing claims as or participating in any
 kind of any class, collective, coordinated, consolidated, mass, and/or representative or other
 kind of group, multi-plaintiff, or joint action against CHEF PREP and no action brought by you
may be consolidated or joined in any fashion with any other proceeding. Where your claims are
 brought and litigated to completion on such an individual basis in a court of competent
 jurisdiction, CHEF PREP agrees to honor your election.
 The parties’ agreement not to require arbitration in these limited instances does not waive the
 enforceability of this Arbitration Agreement as to any other provision (including, but not limited
 to, the waivers provided for in Section 2(a), which will continue to apply in court as well as in
 arbitration), or the enforceability of this Arbitration Agreement as to any other controversy, claim,
 or dispute.
 (c) Rules and Governing Law.
 For disputes arising in California, the arbitration will be administered by ADR Services, Inc.
 (“ADR”) in accordance with ADR’s Arbitration Rules (the “ADR Rules”) in effect at the time that
 the claim is brought, unless the parties agree otherwise in writing. The ADR Rules are available
 at www.adrservices.com or by searching for “ADR Arbitration Rules” using a search engine such
 as www.google.com. The arbitration shall be heard by one arbitrator (the “Arbitrator”) selected in
 accordance with the ADR Rules.
 For disputes arising outside of California (or for disputes arising in California only if ADR cannot
 or will not administer the arbitration), the parties shall be required to meet and confer to select a
 neutral arbitration provider. Such an arbitration provider shall have operations in the state in
 which the dispute arises. If the parties are unable to mutually agree upon an arbitration provider,
 then either party may invoke 9 U.S.C. § 5 to request that a court of competent jurisdiction
 appoint an arbitration provider with operations in the state in which the dispute arises. Any
 arbitration provider appointed by a court under 9 U.S.C. § 5 shall conduct arbitration solely on
 an individualized basis as set forth in this Section 2. Once the parties mutually agree upon a
 neutral arbitration provider, or an arbitrator provider is appointed under 9 U.S.C. § 5, the
 ensuing arbitration shall commence pursuant to the rules of the designated arbitration provider,
 except as designated herein. Once an arbitration provider is agreed upon or appointed, an
 Arbitrator shall be appointed. The Arbitrator will be either (1) a retired judge or (2) an attorney
 licensed to practice law in the state where the arbitration is conducted with experience in the law
 underlying the dispute. The Arbitrator will be selected by the parties from the applicable
 arbitration provider’s roster of arbitrators. If the parties are unable to agree upon an Arbitrator
 after a good faith meet and confer effort, then the applicable arbitration provider will appoint the
 Arbitrator in accordance with its rules.
 Notwithstanding any choice of law or other provision in these Terms, the parties agree and
 acknowledge that this Arbitration Agreement evidences a transaction involving interstate
 commerce and that the Federal Arbitration Act, 9 U.S.C. § 1, et seq. (“FAA”), will govern its
 interpretation and enforcement and proceedings pursuant thereto. It is the intent of the parties to
 be bound by the provisions of the FAA for all purposes, including, but not limited to,
 interpretation, implementation, enforcement, and administration of this Arbitration Agreement,
 and that the FAA and the applicable arbitration provider’s rules shall preempt all state laws to
 the fullest extent permitted by law. All statutes of limitations that would otherwise be applicable
 will apply to any arbitration proceeding. If the FAA and applicable arbitration provider’s rules are
 found to not apply to any issue regarding the interpretation or enforcement of this Arbitration
 Agreement, then that issue shall be resolved under the laws of the state where you reside when
 you accept these Terms.
Any dispute, claim, or controversy arising out of or relating to incidents or accidents resulting in
 personal injury (including but not limited to sexual assault or harassment claims) or death that
 you allege occurred in connection with your use of the Services, whether before or after the date
 you agreed to the Terms, shall be governed by and construed in accordance with the laws of the
 state in which the incident or accident occurred.
 (d) Process.
 Pre-Arbitration Dispute Resolution and Notification. The parties agree that good-faith informal
 efforts to resolve disputes often can result in a prompt, low-cost, and mutually beneficial
 outcome. The parties therefore agree that, before either party demands arbitration against the
 other, we will personally meet and confer, via telephone or videoconference, in a good-faith
 effort to resolve informally any claim covered by this Arbitration Agreement. Multiple individuals
 initiating claims cannot participate in the same informal telephonic dispute resolution
 conference. If you are represented by counsel, your counsel may participate in the conference,
 but you shall also fully participate in the conference. The party initiating the claim must give
 notice to the other party in writing of their intent to initiate an informal dispute resolution
 conference, which shall occur within 60 days after the other party receives such notice, unless
 an extension is mutually agreed upon by the parties. To notify CHEF PREP that you intend to
 initiate an informal dispute resolution conference, write to CHEF PREP Technologies, Inc., Attn:
 Legal Department, 1725 3rd Street, San Francisco, CA 94158, providing your name, the
 telephone number(s) associated with your CHEF PREP account (if any), the email address(es)
 associated with your CHEF PREP account, and a description of your claim. Engaging in an
 informal dispute resolution conference is a condition precedent that must be fulfilled before
 commencing arbitration, and the Arbitrator shall dismiss any arbitration demand filed before
 completion of an informal dispute resolution conference. The statute of limitations and any filing
 fee deadlines shall be tolled while the parties engage in the informal dispute resolution process
 required by this paragraph.
 Initiating Arbitration. In order to initiate arbitration following the conclusion of the informal dispute
 resolution process required by this Section, a party must provide the other party with a written
 demand for arbitration and file the demand with the applicable arbitration provider, as
 determined by Section 2(c). A party initiating an arbitration against CHEF PREP must send the
 demand for arbitration to CHEF PREP, LLC, Attn: Legal Department, or serve the Demand on
 CHEF PREP’s registered agent for service of process, c/o CHEF PREP, LLC. (the name and
 current contact information for the registered agent in each state are available online here).
 Additionally, a party initiating arbitration against CHEF PREP must send an electronic version of
 the demand for arbitration to the Arbitration Provider, and must send an electronic version of the
 as-filed demand to legal@CHEFPREP.com.
 By signing the demand for arbitration, counsel certifies to the best of counsel’s knowledge,
 information, and belief, formed after an inquiry reasonable under the circumstances, that (i) the
 demand for arbitration is not being presented for any improper purpose, such as to harass,
 cause unnecessary delay, or needlessly increase the cost of dispute resolution; (ii) the claims
 and other legal contentions are warranted by existing law or by a nonfrivolous argument for
 extending, modifying, or reversing existing law or for establishing new law; and (iii) the factual
 contentions have evidentiary support or, if specifically so identified, will likely have evidentiary
 support after a reasonable opportunity for further investigation or discovery. The Arbitrator shall
be authorized to afford any relief or impose any sanctions available under Federal Rule of Civil
 Procedure 11 or any applicable state law for either party’s violation of this requirement.
 (e) Location.
 Unless you and CHEF PREP otherwise agree, if you reside in the United States, the arbitration
 will be conducted in the county where you reside. If you do not reside in the United States, the
 arbitration will be conducted in the county where the dispute arises. Your right to a hearing will
 be determined by the applicable arbitration provider’s rules. Subject to the applicable arbitration
 provider’s rules, the Arbitrator will have the discretion to direct a reasonable exchange of
 information by the parties, consistent with the expedited nature of the arbitration.
 (f) Offers of Judgment.
 At least 10 days before the date set for the arbitration hearing, any party may serve an offer in
 writing upon the other party to allow judgment on specified terms. If the offer is accepted, the
 offer with proof of acceptance shall be submitted to the arbitrator, who shall enter judgment
 accordingly. If the offer is not accepted prior to the arbitration hearing or within 30 days after it is
 made, whichever occurs first, it shall be deemed withdrawn, and cannot be given in evidence
 upon the arbitration. If an offer made by one party is not accepted by the other party, and the
 other party fails to obtain a more favorable award, the other party shall not recover their
 post-offer costs and shall pay the offering party’s costs from the time of the offer.
 (g) Arbitrator’s Decision.
 The Arbitrator will render an award within the time frame specified in the applicable arbitration
 provider’s rules. Judgment on the arbitration award may be entered in any court of competent
 jurisdiction. The Arbitrator may award declaratory or injunctive relief only in favor of the claimant
 and only to the extent necessary to provide relief warranted by the claimant’s individual claim.
 An Arbitrator’s decision shall be final and binding on all parties.
 The Arbitrator is not bound by decisions reached in separate arbitrations, and the Arbitrator’s
 decision shall be binding only upon the parties to the arbitration that are the subject of the
 decision.
 The Arbitrator shall award reasonable costs incurred in the arbitration to the prevailing party in
 accordance with the law(s) of the state in which arbitration is held.
 (h) Fees.
 With the exception of the provisions governing payment of arbitration costs set forth above, your
 responsibility to pay any filing, administrative, and arbitrator fees will be solely as set forth in the
 applicable arbitration provider’s rules and shall be up to the amount you would be required to
 pay if you filed a claim in court.
 If you have a gross monthly income of less than 300% of the federal poverty guidelines, you are
 entitled to a waiver of arbitration fees and costs, exclusive of arbitrator fees. If you believe that
 you meet the requirements to obtain a fee waiver, and your demand for arbitration arises
 outside of California, then you may request a fee waiver only by submitting to the arbitration
 provider AO 240, Application to Proceed in District Court Without Prepaying Fees or Costs
 (found here), or a declaration under oath containing all the information required by AO 240; if
 your demand for arbitration arises in California, then you must submit a declaration under oath
 providing your monthly income and the number of persons in your household.
 Any and all disputes regarding a party’s obligation to pay any arbitration fees or costs that arise
 after an arbitrator is appointed shall be determined solely by the arbitrator. If such a dispute
arises before an arbitrator has been appointed, and if no Special Master has been requested by
 either party pursuant to Section 2(a)(3)(c)(i) of these Terms, the parties agree that (i) the due
 date for any disputed fees shall be stayed pending resolution of the parties’ dispute, (ii) a panel
 of three arbitrators shall be appointed to resolve the parties’ dispute concerning a party’s
 obligation to pay fees or costs of arbitration, (iii) the panel of arbitrators shall be appointed by
 each party selecting one arbitrator from the arbitration provider’s roster to serve as neutral
 arbitrators, and these arbitrators shall appoint a third neutral arbitrator. If the parties’ arbitrators
 cannot agree on a third arbitrator, the arbitration administrator will select the third arbitrator, (iv)
 CHEF PREPshall not pay any administrative fees or costs incidental to the appointment of a
 panel of arbitrators under this provision, as well as any fees or costs that would not be incurred
 in a court proceeding, such as payment of the fees of the arbitrator(s), as well as room rental,
 and (v) the arbitrator(s) shall issue a written decision with findings of fact and conclusions of law.
 If two or more fee disputes between a claimant and CHEF PREP arise at or around the same
 time, the disputes may be consolidated for resolution by a single arbitrator or panel of arbitrators
 either at the agreement of the parties or the election of the party common to all such disputes.
 (i) Severability and Survival.
 If any portion of this Arbitration Agreement is found to be unenforceable or unlawful for any
 reason, (i) the unenforceable or unlawful provision shall be severed from these Terms; (ii)
 severance of the unenforceable or unlawful provision shall have no impact whatsoever on the
 remainder of the Arbitration Agreement or the parties’ ability to compel arbitration of any
 remaining claims on an individual basis pursuant to the Arbitration Agreement; and (iii) to the
 extent that any claims must therefore proceed on a class, collective, consolidated, or
 representative basis, such claims must be litigated in a civil court of competent jurisdiction and
 not in arbitration, and the parties agree that litigation of those claims shall be stayed pending the
 outcome of any individual claims in arbitration</p>

            <h3>3. The Services</h3>
            <p> The Services enable you and other consumers to find, request, or receive (i) Third-Party
 Services from third party service providers, including without limitation, merchants, retailers,
 grocers, restaurants, independent drivers, delivery persons, and autonomous vehicles or
 autonomous vehicle fleet providers (“Third-Party Providers”); (ii) related personalized content,
 including features, recommendations and advertisements for products or services tailored to
 your needs and interests; and (iii) certain supporting services, including providing you the ability
 to express certain preferences about the Third-Party Services or Third-Party Providers, payment
 processing and customer support. Unless otherwise agreed by CHEF PREP in a separate
 written agreement with you, these Services are made available solely for your personal,
 noncommercial use.
 Once you make a request, CHEF PREP notifies Third-Party Providers that an opportunity is
 available so that the Third-Party Provider may complete your request. It is up to the Third-Party
 Provider to decide whether or not to offer Third-Party Services to you or at all, and it is up to you
 to decide whether or not to accept such services from a Third-Party Provider. Please note that
 once your request for the Services has begun, you may no longer have the option to reschedule
or cancel. If CHEF PREP is able to reschedule or cancel your request, you may be charged a
 fee and/or may not be refunded for items that have been purchased on your behalf.
 CHEF PREPIS NOTACOMMONORMOTORCARRIERANDDOESNOTTRANSPORT
 PASSENGERS ORGOODS.GENERALLY, THE SERVICES ARE ONLYOPENTO
 REGISTERED USERS OFTHESERVICESANDNOTTOTHEGENERALPUBLIC.YOUR
 ABILITY TO REQUEST, AND IF APPLICABLE, OBTAIN THIRD-PARTY SERVICES FROM
 THIRD-PARTY PROVIDERS IN CONNECTION WITH THE USE OF THE SERVICES DOES
 NOTESTABLISH CHEF PREP ASAPROVIDEROFANYTHINGOTHERTHANTHE
 SERVICES. THIRD-PARTY PROVIDERS ARE INDEPENDENT AND NOT ACTUAL AGENTS,
 APPARENT AGENTS, OSTENSIBLE AGENTS, OR EMPLOYEES OF CHEFPREPINANY
 WAY. ANY EFFORT, FEATURE, PROCESS, POLICY, STANDARD, OR OTHER EFFORT
 UNDERTAKEN BYCHEFPREPTOFACILITATE YOUR RECEIPT OF THIRD PARTY
 SERVICES OR IN THE INTEREST OF SAFETY ORSECURITY(WHETHERREQUIREDBY
 APPLICABLE REGULATIONS OR NOT) IS NOT AN INDICIA OF AN EMPLOYMENT, ACTUAL
 AGENCY, APPARENT AGENCY, OR OSTENSIBLE AGENCY RELATIONSHIP WITH A
 THIRD-PARTY PROVIDER.
 App Stores.
 The availability of the Services may be dependent on the third-party from which you received
 the license to the CHEF PREP App, e.g., the Apple iPhone or Android app stores (“App Store”).
 These Terms are between you and CHEF PREP and not with the App Store and CHEF PREP is
 responsible for the provision of Services as described in these Terms. However, if you
 downloaded the CHEF PREP App from the Apple App Store, Apple and its subsidiaries are
 third-party beneficiaries of these Terms. Upon your acceptance of these Terms, Apple shall have
 the right (and will be deemed to have accepted the right) to enforce these Terms against you as
 a third-party beneficiary thereof. These Terms incorporate by reference Apple’s Licensed
 Application End User License Agreement, for purposes of which, you are the “end-user.” In the
 event of a conflict in the terms of the Licensed Application End User License Agreement and
 these Terms, these Terms will control.
 Ownership; License; and Restrictions.
 The Services and all rights, title, and interest, including all related intellectual property rights
 therein are and shall remain CHEF PREP’s property or the property of CHEF PREP’s licensors.
 These Terms are not a sale and do not convey or grant to you any rights in or related to the
 Services, or any intellectual property rights owned by CHEF PREP or its licensors, except for
 the limited license granted herein.
 Subject to your compliance with these Terms, CHEF PREP grants you a limited, non-exclusive,
 non-sublicensable, revocable, non-transferable license to: (i) access and use the CHEF PREP
 App solely in connection with your use of the Services on your personal device; and (ii) access
 and use any content, information and related materials that may be made available through the
 Services, in each case solely for your personal, noncommercial use. Any rights not expressly
 granted herein are reserved by CHEF PREP and CHEF PREP’s licensors. You agree that you
 will not use CHEF PREP’s copyrights, trademarks, service marks, or trade dress, aside from
 use incidental to your use of the Services, without express, written permission from CHEF
 PREP. This prohibition includes use in domain names, websites, and social media accounts.
 You may not: (i) remove any copyright, trademark or other proprietary notices from any portion
of the Services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license,
 lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or
 otherwise exploit the Services except as expressly permitted by CHEF PREP; (iii) decompile,
 reverse engineer or disassemble the Services except as may be permitted by applicable law;
 (iv) link to, mirror or frame any portion of the Services; (v) cause or launch any programs or
 scripts for the purpose of, or which result in, unduly burdening or hindering the operation and/or
 functionality of any aspect of the Services; or (vi) attempt to gain unauthorized access to or
 impair any aspect of the Services or its related systems or networks</p>

            <h3>4. Third-Party Services (including Autonomous Vehicles) and Content</h3>
            <p> While many Third-Party Services are available in the CHEF PREP App, certain Third-Party
 Services or content are only accessible by exiting the CHEF PREP App (“Out-of-App
 Experiences”). Once you click on a link to access Out-of-App Experiences, you will be subject to
 the terms and conditions and privacy policy of that website, destination, or Out-of-App
 Experience provider, which are different from CHEF PREP’s. CHEF PREP will not warn you that
 you have left the Services or that you are subject to the terms and conditions (including privacy
 policies) of another website, destination, or Out-of-App Experience provider. You use all links in
 third-party websites and advertisements at your own risk as these are not part of the Services
 and are not controlled by CHEF PREP. CHEF PREP does not endorse such Out-of-App
 Experience providers and in no event shall CHEF PREP be responsible or liable for any
 products or services of such providers.
 Third-Party Services made available to you through the CHEF PREP App may be provided by
 an autonomous vehicle. An autonomous vehicle is a vehicle that is capable of operating at, or is
 equipped with an automated driving system that will enable the vehicle to operate at SAE Levels
 3, 4 or 5 of driving automation as defined in the J3016 April 2021 SAE International specification
 (“Autonomous Vehicle” or “AV”). Autonomous Vehicles are operated by Third-Party Providers
 that operate a fleet of one or more AVs and may employ or contract with individuals to manage,
 monitor, or operate its AVs while such vehicles are in motion (such Third-Party Providers,
 "Autonomous Vehicle Fleet Providers"). Your access to Third-Party Services provided by AV
 may be subject to Autonomous Vehicle Fleet Providers' terms as applicable.
 Third-Party Services may be subject to additional terms, conditions, fees, and policies imposed
 by the Third-Party Provider. In the event of a conflict in the terms of any Third-Party Services
 and these Terms, these Terms shall control with respect to CHEF PREP and your agreements
 with CHEF PREP herein, and the limitations of liability set forth in Section 8 shall also apply to
 claims involving a Third-Party Provider. The Arbitration Agreement provisions in Section 2 above
 shall apply instead of the terms of any Third-Party Services for all purposes except with respect
 to claims that are solely against the Third-Party Provider</p>
 
 <h3>5. Accessing the Services </h3>
            <p>  User Accounts.
 In order to use most aspects of the Services, you must register for and maintain an active
 personal user Services account (“Account”). Unless a specific Service provides otherwise: (i)
 you must be at least 18 years of age, or the age of legal majority in your jurisdiction (if different
 than 18), to obtain an Account, (ii) you may only possess one Account and (iii) you may not
 assign or otherwise transfer your Account to any other person or entity. CHEF PREP maintains
 the right to delete or deactivate duplicate accounts. You are responsible for all activity that
 occurs under your Account, and you agree to maintain the security and secrecy of your Account
 credentials at all times.
 You cannot register for or maintain an Account if you have previously been banned from
 accessing or using the Services. Account registration may require you to submit to CHEF PREP
 certain personal information, such as your name, address, still or live photo, mobile phone
 number and age, as well as at least one valid payment method that you are authorized to use
 and is supported by CHEF PREP (“Account Information”). You are responsible for providing
 accurate Account Information and in certain instances, you may be asked to provide proof of
 age, identity or other method of identity verification to access or use the Services. You may be
 denied access to, or use of, the Services if you refuse to provide (or we are unable to verify)
 proof of age, identity, or other method of identity verification. Additionally, you authorize your
 wireless carrier to use or disclose information about your account and your wireless device, if
 available, to CHEF PREP or its service providers for the duration of your business relationship,
 solely to help CHEF PREP identify you or your wireless device and to prevent fraud.
 CHEF PREPalso disable or delete your account if after registration your account is not
 confirmed (where applicable), your account is unused and remains inactive for an extended
 period of time, if we detect someone may have used it without your permission and we are
 unable to confirm your ownership of the account, or where we are required to do so under
 applicable law. To the maximum extent permitted by applicable law, CHEF PREP and its
 affiliates assume no liability for such loss of access and use and will have no obligations related
 to such loss. If you discontinue your use of CHEF PREP, or we disable your access to or use of
 the Services, these Supplemental Terms shall terminate as an agreement between you and us
 with respect to those Services, but certain sections will survive termination as provided herein.
 Minors.
 You may not authorize third-parties to use your Account, and you may not allow persons under
 the age of 18 to use the Services unless they are accompanied by you or an adult. However, we
 may offer parents and guardians the ability to create Accounts for their children. If you are a
 parent or legal guardian, and you allow your child to use the Services, then these Terms apply
 to you and you are responsible for your child’s activity on the Services. If you are under the age
 to obtain an Account, you must have your parent or legal guardian’s permission to use an
 Account and accept any additional terms required in connection with your access and use of the
 Services as a minor. Please have your parent or legal guardian read these additional terms with
 you. Please note: if there is an incident involving minors, in addition to any mandatory reporting
 obligations, CHEF PREP may also proactively report incidents to the applicable authorities.
 Network Access and Devices.
You are responsible for obtaining the data network access necessary to use the Services. Your
 mobile network's data and messaging rates and fees may apply if you access or use the
 Services from your device. You are responsible for acquiring and updating compatible hardware
 or devices necessary to access and use the Services and any updates thereto. CHEF PREP
 does not guarantee that the Services, or any portion thereof, will function on any particular
 hardware or devices. In addition, the Services may be subject to malfunctions and delays
 inherent in the use of the Internet and electronic communications. CHEF PREP is not
 responsible for any resulting delays, delivery failures, or damage, loss, injury or death.</p>

 <h3>6. User Conduct and Requirements; Communications; and User Content </h3>
            <p>   User Conduct and Requirements.
 In addition to complying with these Terms, you agree to comply with all applicable laws when
 accessing or using the Services, and you may only access or use the Services for lawful
 purposes. You may not access or use the Services to cause nuisance, annoyance,
 inconvenience, damage, or loss to CHEF PREP, the Third-Party Provider, or any other party.
 If you request a ride option with a child restraint system, neither CHEF PREP nor the
 Third-Party Provider is responsible for the safety of a child restraint system that may be
 available in the Third-Party Provider’s vehicle. It is your obligation to ensure that the child
 restraint system is installed correctly and that the child is properly secured in the child restraint
 system. Please refer to your state’s laws regarding specific height, age, and weight
 requirements for using child restraint systems, as well as CHEF PREP’s policies for child
 restraint systems, which may be set forth on city-specific web pages. If you request a ride option
 where a Third-Party Provider agrees to provide you with assistance outside of the vehicle (e.g.,
 CHEF PREPAssist), CHEF PREP is not responsible for any injury, death or incident that may
 arise out of the assistance provided by the Third-Party Provider.
 Subject to the discretion of a Third-Party Provider, you may be allowed to bring a small animal,
 such as a dog or cat, on a ride requested through the Services. For such trips, you are
 responsible for properly securing the animal with a leash, harness, crate / carrier, or through
 other means. You are also responsible for ensuring that the animal does not cause damage or a
 mess in the Third-Party Provider’s vehicle. You may be subject to a Charge for Repair or
 Cleaning under Section 6 below for any damage or mess caused by an animal that is
 transported during a ride requested under your Account. Please note, in accordance with CHEF
 PREP’s policies on Service Animals and Assistive Devices, Service Animals are generally
 permitted to accompany riders without extra charge, regardless of whether it is a Pet Friendly
 Trip.
 For the purpose of assisting us with our compliance and insurance obligations, you agree to
 notify us within 24 hours and provide us with all reasonable information relating to any incident
 or accident that occurs during your use of the Services and you agree to cooperate with any
 investigation and attempted resolution of such incident.
 Communications with CHEF PREP.
 By creating an Account, you electronically agree to accept and receive communications from
 CHEF PREP, Third-Party Providers or third parties providing services to CHEF PREP including
via email, text message, WhatsApp, calls, in-app communications, and push notifications to the
 telephone number(s) or email addresses you provided to CHEF PREP. You may also receive
 communications generated by automatic telephone dialing systems and/or which will deliver
 prerecorded or automated messages sent by or on behalf of CHEF PREP, and/or Third-Party
 Providers, including but not limited to communications concerning requests placed through your
 Account on the Services. Message and data rates may apply.
 You may change your notification preferences by accessing Settings in your Account. To opt out
 of receiving text messages from CHEF PREP, you must reply “STOP” from the mobile device
 receiving the messages. Text messages between you and Third-Party Providers are
 transactional text messages, not promotional text messages. You acknowledge that opting out
 of receiving all communications may impact your use of the Services. Notwithstanding the
 foregoing, if we suspect fraud or unlawful activity on your Account, CHEF PREP may contact
 you using any of the contact information you provided in connection with your Account
 (including via text or voice-recorded message).
 Use of Accounts Owned by Others.
 In the event you use a CHEF PREP product or service that enables use of or billing to another
 person or business, certain information will be shared with that party. This may include
 information regarding the time and date of services you request, the transportation, logistics
 and/or delivery requested, and the associated charges for such services. If used to request
 transportation, we may also share information with such person or business regarding
 safety-related incidents that occur in connection with such transportation. You acknowledge that
 such data sharing is a condition of use of any such CHEF PREP product or service.
 User Provided Content; Feedback.
 Content that you provide to CHEF PREP is governed by CHEF PREP’s Generated Content
 Terms, which are incorporated in these Terms by reference. Feedback that you provide to CHEF
 PREP is governed by CHEF PREP’s Feedback Policy, which are incorporated in these Terms by
 reference</p>

 <h3>7. Payment </h3>
            <p>   Prices & Charges.
 Your use of the Services may result in charges to you for the services or goods you receive from
 CHEF PREPand/or from Third-Party Providers (“Charges”). Prices displayed to you when
 purchasing goods through the Services may be inclusive of retail prices charged by the
 Third-Party Provider and fees paid to CHEF PREP. CHEF PREP will enable your payment of the
 applicable Charges for services or goods obtained through your use of the Services. Charges
 will include applicable taxes where required by law. Charges may include other applicable fees
 such as delivery fees, service fees, product return fees, cancellation fees,
 government-mandated fees (such as bag fees), estimated or actual tolls, and/or surcharges.
 Further, Charges applicable in certain geographical areas may increase substantially during
 times of high demand or due to other marketplace factors.
 With respect to Third-Party Providers, Charges you incur will be owed directly to Third-Party
 Providers, and CHEF PREP will collect payment of those charges from you, on the Third-Party
Provider’s behalf as their limited payment collection agent, and payment of the Charges shall be
 considered the same as payment made directly by you to the Third-Party Provider. Payment to
 a Third-Party Provider of goods or services shall be considered to occur at the moment you
 submit payment through CHEF PREP. You retain the right to request lower Charges from a
 Third-Party Provider for services or goods received by you from such Third-Party Provider at the
 time you receive such services or goods. A Third-Party Provider also retains the right to request
 higher Charges from you for services or goods provided. For example, a Third-Party Provider
 that is a merchant may collect lower or higher charges where the actual goods provided differ
 from the products originally requested, including in connection with differences in quantity,
 weight, or item type. Subject to requests from you to lower such Charges from a Third-Party
 Provider, you agree to pay such higher or lower Charges associated with such product
 differences. CHEF PREP will consider in good faith any request from a Third-Party Provider to
 modify the Charges for a particular service or good. This payment structure is intended to fully
 compensate a Third-Party Provider, if applicable, for the services or goods obtained in
 connection with your use of the Services.
 There also may be certain Charges you incur that will be owed and paid directly to CHEF PREP
 or its affiliates. For the avoidance of doubt, CHEF PREP does not charge a fee for you to
 access the CHEF PREP App, but may charge you a fee or any other Charge for accessing
 Third-Party Services. Even if not indicated in the CHEF PREP App, the prices for product or
 menu items displayed through the Services may differ from the prices offered or published by
 Third-Party Providers for the same product or menu items, including as may be offered or
 published at a physical location operated by a Third-Party Provider, and/or from prices available
 at other third-party websites/mobile applications. Prices for product or menu items displayed
 through the Services may not be the lowest prices at which the product or menu items are sold.
 The product or menu item prices displayed through the Services or fees charged by and paid to
 CHEF PREPmayvary based on whether you choose to pick up your order or have it delivered.
 All Charges and payments will be enabled by CHEF PREP using the preferred payment method
 designated in your Account, after which you will receive a receipt. If your primary Account
 payment method is determined to be expired, invalid or otherwise not able to be charged, you
 agree that CHEF PREP may use another available payment method in your Account.
 As between you and CHEF PREP, CHEF PREP reserves the right to establish or adjust
 Charges for any or all services or goods obtained through the use of the Services at any time.
 CHEF PREPwill use reasonable efforts to inform you of Charges that may apply, provided that
 you will be responsible for Charges incurred under your Account regardless of your awareness
 of such Charges or the amounts thereof.
 Refunds.
 Charges paid by you are final and non-refundable, unless otherwise determined by CHEF
 PREP and the Third-Party Provider assessing the Charge.
 Promotional Offers.
 Certain users may, from time to time, receive promotional offers and discounts that result in
 different amounts charged for the same or similar services or goods obtained through the use of
 the Services, and you agree that such promotional offers and discounts, unless also made
 available to you, shall have no bearing on your use of the Services or the Charges applied to
you. Promotional offers and discounts are subject to change or withdrawal at any time and
 without notice.
 Gratuity.
 Except for amounts provided by you through the Services as part of the “tip” feature, CHEF
 PREP does not designate any portion of your payment as a tip or gratuity to a Third-Party
 Provider. You understand and agree that, while you are free to provide additional payment as a
 gratuity to any Third-Party Provider who provides you with services or goods obtained through
 the Service, you are under no obligation to do so.
 Damage, Cleaning, Lost and Found, and Charges for Violation of Terms.
 CHEF PREPmaycharge you a fee on behalf of Third-Party Providers if, during your use of the
 Services, you have caused damage to a vehicle or property that requires repair or cleaning
 (“Repair” or “Cleaning”). The amount of such fee shall be determined, in CHEF PREP’s sole
 discretion, based on the type of damage and the severity. CHEF PREP reserves the right to
 verify or otherwise require documentation of damages prior to processing a fee. In the event
 that a Repair or Cleaning request is verified by CHEF PREP in CHEF PREP’s reasonable
 discretion, CHEF PREP reserves the right to facilitate payment for the reasonable cost of such
 Repair or Cleaning using your payment method designated in your Account. Such amounts, as
 well as those pertaining to lost and found goods, will be transferred by CHEF PREP to a
 Third-Party Provider, if applicable, and are non-refundable. Additionally, if you fail to comply with
 these Terms, you may be responsible for Charges, including without limitation, for transactions
 that could not be completed properly, arising out of or in connection with your failure to comply
 with these Terms.</p>

 <h3>8. Disclaimers; Limitation of Liability; and Indemnity </h3>
            <p>   Disclaimers.
 THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” CHEF PREP DISCLAIMS
 ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT
 EXPRESSLY SET OUTIN THESETERMS, INCLUDING THE IMPLIED WARRANTIES OF
 MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
 IN ADDITION, CHEF PREP MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE
 REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR AVAILABILITY OF
 THE SERVICES ORANYSERVICESORGOODSREQUESTEDTHROUGHTHEUSEOF
 THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.
 CHEF PREPDOESNOTGUARANTEETHEQUALITY,SUITABILITY, SAFETY OR ABILITY
 OF THIRD-PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF
 YOURUSEOFTHESERVICES,ANDANYSERVICEORGOODREQUESTEDOR
 OBTAINED FROM THIRD-PARTY PROVIDERS IN CONNECTION THEREWITH, REMAINS
 SOLELY WITH YOU, TO THEMAXIMUMEXTENTPERMITTEDUNDERAPPLICABLE LAW.
 CHEF PREPDOESNOTCONTROL,MANAGEORDIRECTANYTHIRD-PARTY
 PROVIDERS. THIRD-PARTY PROVIDERS ARE NOT ACTUAL AGENTS, APPARENT
 AGENTS, OSTENSIBLE AGENTS, OR EMPLOYEES OF CHEF PREP. IF A DISPUTE ARISES
BETWEENYOUANDORANYOTHERTHIRDPARTY,YOURELEASECHEFPREPFROM
 LOSSES OFEVERYKINDANDNATURE,KNOWNANDUNKNOWN,SUSPECTEDAND
 UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY
 CONNECTEDWITHSUCHDISPUTES.
 CHEF PREPDOESNOTCONTROL,ENDORSEORTAKERESPONSIBILITYFORANY
 USERCONTENTORTHIRD-PARTY CONTENTAVAILABLE ON ORLINKED TOBYTHE
 SERVICES. CHEF PREP CANNOT ANDDOESNOTREPRESENTORWARRANTTHATTHE
 SERVICES ARE FREE OFVIRUSES OROTHERHARMFULCOMPONENTS.
 CHEF PREP’S USE OFALGORITHMSINANATTEMPTTOPROVIDESERVICESOR
 IMPROVE THE EXPERIENCE OF USERSANDTHESECURITYANDSAFETYOFTHE
 SERVICES DOES NOTCONSTITUTE AGUARANTEEORWARRANTYOFANYKIND,
 EXPRESSED ORIMPLIED.
 Limitation of Liability.
 CHEF PREPSHALLNOTBELIABLEFORINDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
 PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA,
 PERSONAL INJURY OR DEATH, ORPROPERTYDAMAGERELATEDTO, INCONNECTION
 WITH, OR OTHERWISE RESULTING FROM ANYUSEOFTHESERVICES,REGARDLESS
 OF THENEGLIGENCE(EITHER ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF
 CHEF PREP, EVEN IF CHEF PREPHASBEENADVISEDOFTHEPOSSIBILITY OF SUCH
 DAMAGES.
 CHEF PREPSHALLNOTBELIABLEFORANYDAMAGES,LIABILITY OR LOSSES ARISING
 OUTOF: (i) YOUR USE OF ORRELIANCE ONTHESERVICESORYOURINABILITY TO
 ACCESS ORUSETHESERVICES;OR(ii) ANY TRANSACTION OR RELATIONSHIP
 BETWEENYOUANDANYTHIRD-PARTYPROVIDER, EVENIF CHEF PREPHASBEEN
 ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. CHEF PREP SHALL NOTBELIABLE
 FORDELAYORFAILURE IN PERFORMANCERESULTING FROMCAUSESBEYONDCHEF
 PREP'S REASONABLE CONTROL. YOU ACKNOWLEDGETHATTHIRD-PARTY
 PROVIDERS PROVIDING TRANSPORTATION SERVICES REQUESTED THROUGH SOME
 CHEF PREPSERVICES MAYOFFERRIDESHARING ORPEER-TO-PEER
 TRANSPORTATION SERVICES AND MAY NOT BEPROFESSIONALLY LICENSED OR
 PERMITTED. YOU ACKNOWLEDGE THATTHIRD-PARTY PROVIDERS ARE NOT
 OSTENSIBLE AGENTS, APPARENT AGENTS, ACTUAL AGENTS, OR EMPLOYEES OF
 CHEF PREP.
 THE SERVICES MAY BEUSEDBYYOUTOREQUESTANDSCHEDULE
 TRANSPORTATION, GOODS, OR LOGISTICS SERVICES WITH THIRD-PARTY PROVIDERS,
 BUT YOUAGREETHATCHEFPREPHASNORESPONSIBILITYORLIABILITY TO YOU
 RELATED TO ANYTRANSPORTATION, GOODS ORLOGISTICS SERVICES PROVIDED TO
 ORNOTPROVIDEDTOYOUBYTHIRD-PARTYPROVIDERSOTHERTHANAS
 EXPRESSLY SET FORTHIN THESE TERMS.
 CHEF PREPSHALLNOTBELIABLEFORANYDAMAGES,LIABILITY OR LOSSES ARISING
 OUTOFLACKOFORIMPROPERINSTALLATIONORUSEOFCHILDRESTRAINT
 SYSTEMS FORGUESTSONRIDESREQUESTEDTHROUGHTHESERVICESFORWHOM
 A CHILD RESTRAINT SYSTEM IS LEGALLY REQUIRED.
THE LIMITATIONS AND DISCLAIMERS IN THIS SECTION DO NOT PURPORT TO LIMIT
 LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOTBEEXCLUDED
 UNDERAPPLICABLE LAW. BECAUSE SOME STATES ORJURISDICTIONS DO NOT ALLOW
 THE EXCLUSION OF ORTHELIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
 INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, CHEF PREP’S LIABILITY
 SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. THIS PROVISION SHALL HAVE
 NOEFFECTONCHEFPREP’SCHOICEOFLAWPROVISIONSETFORTHBELOW.
 Indemnity.
 You agree to indemnify and hold CHEF PREP and its affiliates and their officers, directors,
 employees, and agents harmless from and against any and all actions, claims, demands,
 losses, liabilities, costs, damages, and expenses (including attorneys’ fees), arising out of or in
 connection with: (i) your use of the Services or services or goods obtained through your use of
 the Services; (ii) your breach or violation of any of these Terms; (iii) CHEF PREP's use of your
 User Content; or (iv) your violation of the rights of any third party, including Third-Party
 Providers.</p>

 <h3>9. Other Provisions </h3>
            
<p>   Choice of Law.
 These Terms shall be governed by and construed in accordance with the laws of the state in
 which your dispute arises, without regard to the choice or conflict of law principles of any
 jurisdiction, except as may be otherwise provided in the Arbitration Agreement in Section 2
 above or in Supplemental Terms applicable to your region. This Choice of Law provision applies
 only to the interpretation of these Terms, and these provisions shall not be interpreted as
 generally extending any state’s law to you if your dispute did not arise in that state.
 Any dispute, claim, or controversy arising out of or relating to incidents or accidents resulting in
 personal injury or death (including but not limited to sexual assault or harassment claims) that
 you allege occurred in connection with your use of the Services, whether before or after the date
 you agreed to these Terms, shall be governed by and construed in accordance with the laws of
 the state in which the incident or accident occurred.
 Choice of Forum.
 Any dispute, claim, or controversy arising out of or relating to these Terms or the existence,
 breach, termination, enforcement, interpretation or validity thereof, shall be brought exclusively
 in the state or federal courts of the state in which the dispute, claim or controversy arose,
 notwithstanding that other courts may have jurisdiction over the parties and subject matter,
 except as may be otherwise provided by the Arbitration Agreement above or in Supplemental
 Terms applicable to your region.
 Notwithstanding the foregoing, any dispute, claim, or controversy arising out of or relating to
 incidents or accidents resulting in personal injury (including but not limited to sexual assault or
 harassment claims) that you allege occurred in connection with your use of the Services,
 whether before or after the date you agreed to these Terms, shall be brought exclusively in the
 state or federal courts in the state in which the incident or accident occurred, notwithstanding
 that other courts may have jurisdiction over the parties and subject matter, and except as may
be otherwise provided in the Arbitration Agreement in Section 2 or in Supplemental Terms
 applicable to your region, to the extent permitted by law.
 The foregoing Choice of Law and Choice of Forum provisions do not apply to the Arbitration
 Agreement in Section 2, and we refer you to Section 2 for the applicable provisions for such
 disputes.
 Claims of Copyright and Trademark Infringement.
 Claims of copyright and trademark infringement should be sent to CHEF PREP’s designated
 agent. Please see CHEF PREP’s Copyright Policy or Trademark Policy for the designated
 address and additional information.
 Notice.
 CHEF PREPmaygive notice by means of a general notice on or through the Services,
 electronic mail to the email address associated with your Account, telephone or text message to
 any phone number provided in connection with your Account, or by written communication sent
 by first class mail or pre-paid post to any address connected with your Account.
 General.
 You may not assign these Terms without CHEF PREP’s prior written approval. CHEF PREP
 may assign these Terms without your consent to: (i) a subsidiary or affiliate; (ii) an acquirer of
 CHEF PREP's equity, business or assets; or (iii) a successor by merger. Any purported
 assignment by you in violation of this Section shall be void. No joint venture, partnership,
 employment, or agency relationship exists between you, CHEF PREP, any Third-Party Provider,
 or any Out-of-App Experience Provider as a result of these Terms or use of the Services. If any
 provision of these Terms is held to be invalid or unenforceable, such provision shall be struck
 and the remaining provisions shall be enforced to the fullest extent under law. CHEF PREP's
 failure to enforce any right or provision in these Terms shall not constitute a waiver of such right
 or provision unless acknowledged and agreed to by CHEF PREP in writing. This provision shall
 not affect the Severability and Survivability section of the Arbitration Agreement of these Terms.</p>
          </div>
        </section>

        <section className="privacy-section">
          <h2>Privacy Policy</h2>
          <div className="legal-text">
            <h3>1. Data provided by users. This includes:</h3>
            <p>Account information: We collect data when users create or update their CHEF PREP accounts, or place orders via guest checkout features. This includes first and last name, email, phone number, login name and password, address, profile picture, payment or banking information (including related payment verification information), user settings (including accessibility settings), and loyalty program information for CHEF PREP partners.

For drivers and delivery persons, this also includes vehicle or insurance information, emergency contact information, and evidence of health or fitness to provide services using CHEF PREP apps. 
Background check information (drivers and delivery persons): This includes information submitted during the driver/delivery person application process, such as driver history or criminal record (where permitted by law), license status, known aliases, prior addresses, and right to work. This information may be collected by CHEF PREP service providers.
Identity verification documents and photos: This includes government issued identification such as driver’s license or passports (which may contain identification photos and numbers, date of birth, and gender), and user-submitted photos such as selfies and profile pictures.
Demographic data: We collect demographic data such as birth date/age, gender or occupation when necessary to enable certain features, or provide access to age-restricted products or services. For example, we collect users’ date of birth or age to verify eligibility to use CHEF PREP Cash or CHEF PREP Money, or when they purchase alcohol or cannabis products. We also collect or infer (using first name) gender information to enable female or non-binary users to designate their preference for providing or receiving services to/from female or non-binary users, as well as for marketing and advertising.

We also collect demographic data, such as age group and household composition, through user surveys.
User content: We collect data (including chat logs and call recordings) when users contact CHEF PREP customer support, provide ratings or feedback for users, restaurants or merchants, use features that enable users to upload content or submit recordings (including in-app audio recordings or dashboard cameras recordings), or otherwise contact CHEF PREP.

Please see here (riders), here (drivers), and here (delivery persons) for more information about how ratings provided by other users are determined and used.
Travel information: We collect travel itinerary information, including times and dates of upcoming flight, lodging or car rental reservations, from users of our CHEF PREP Travel feature. We collect such information when users manually input their information into their CHEF PREP Travel itinerary.
</p>

            <h3>2. Data created during use of our services. This includes: </h3>
            <p>Location data (driver and delivery person): We collect precise and approximate location data from drivers’ and delivery persons’ mobile devices when the CHEF PREP app is running in the foreground (app open and on-screen) or background (app open but not on-screen).
Location data (riders and order recipients). We collect precise and/or approximate location information from riders’ and order recipients’ mobile devices if they enable us to do so via their device settings.

CHEF PREP collects such data from the time a ride or order is requested until it is finished, and any time the app is running in the foreground (app open and on-screen). See “Choice and transparency” below for information on how riders and order recipients can enable precise location data collection.

Riders and order recipients may use the CHEF PREP apps without enabling collection of location data from their mobile devices. However, this may affect certain features in the CHEF PREP apps. For example, a rider who has not enabled precise location data will have to manually enter their pickup address.

In addition, precise location data collected from a driver’s device during a trip is linked to the rider’s account, even if the rider has not enabled precise location data to be collected from their device. This data is used for purposes such as customer support, fraud detection, insurance, litigation and receipt generation.
Transaction information: We collect transaction information related to the use of our services, including the type of services requested or provided; trip or order details (such as date and time, requested pick-up and drop off addresses, distance traveled and items ordered, such as food, prescription drugs or other delivery items); and payment transaction information (such as a restaurant’s or merchant's name and location, amount charged, and payment method). We also associate a user’s name with that of anyone who uses their promotion code.

This also includes information provided by users when placing their order, such as their allergies information. .
Usage data: We collect data about how users interact with our services. This includes access dates and times, app features or pages viewed, browser type, and app crashes and other system activity.
Device data: We collect data about the devices used to access our services, including the hardware models, device IP address or other unique device identifiers, operating systems and versions, software, preferred languages, advertising identifiers, device motion data, and mobile network data.
Communications data: We collect data regarding communications between users that are enabled through CHEF PREP’s apps. This includes communication type (phone, text or in-app message), date/time, and content (including recordings of phone calls solely when users are notified of the recording in advance).
</p>

            <h3>3. Data from other sources. These include:</h3>
            <p>users participating in our referral programs. For example, when a user refers another person, we receive the referred person’s data from that user.
CHEF PREP account owners who request services for or on behalf of other users (such as friends or family members), or who enable other users to request or receive services through their business accounts (such as Enterprise Customers).
users or others providing information in connection with claims or disputes.
CHEF PREP business partners through which users create or access their CHEF PREP account, such as payment providers, social media services, or apps or websites that use CHEF PREP’s APIs or whose APIs CHEF PREP uses. 
CHEF PREP business partners in connection with debit or credit cards issued by a financial institution in partnership with CHEF PREP to the extent disclosed in the terms and conditions for the card.
service providers who help us verify users’ identity, background information, and eligibility to work, detect fraud, and screen users in connection with sanctions, anti-money laundering, or know-your-customer requirements. 
insurance, vehicle, or financial services providers for drivers and/or delivery persons.
partner transportation companies (for drivers or delivery persons who use our services through an account associated with such a company).
publicly available sources.
marketing partners and service providers, including banks in connection with cash back programs, and data resellers.
law enforcement officials, public health officials, and other government authorities.
</p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Legal; 
