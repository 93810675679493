import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import About from './About';
import Contact from './Contact';
import FAQ from './FAQ';
import Legal from './pages/Legal';
import '../css/App.css';
import logo from '../images/cp-white.png';
import Footer from './components/Footer';


import Home from './Home';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    const isOpen = !isMenuOpen;
    setIsMenuOpen(isOpen);
    document.body.classList.toggle('menu-open', isOpen);
  };

  const handleNavClick = () => {
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
    document.body.classList.remove('menu-open');
  };

  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <div className="logo">
            <Link to="/" onClick={handleNavClick}>
              <img src={logo} alt="Chef Logo" />
            </Link>
          </div>
          
          <div className="hamburger" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div 
            className={`nav-links ${isMenuOpen ? 'active' : ''}`}
            onClick={handleNavClick}
          >
            {[
              { to: "/", text: "Home" },
              { to: "/about", text: "About" },
              { to: "/faq", text: "FAQ" },
              { to: "/contact", text: "Contact Us" },
              { to: "/subscribe", text: "Subscribe" },
              { to: "/vendors", text: "Vendors", className: "contact-btn" }
            ].map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className={link.className}
                onClick={handleNavClick}
              >
                {link.text}
              </Link>
            ))}
          </div>
        </nav>

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/legal" element={<Legal />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
